import React, { Suspense } from 'react';
import { useEffect, useState } from 'react';
import './App.css';
import {RouterProvider} from "react-router-dom";
import {desktopRouter} from "./desktopRouter";
import {mobileRouter} from "./mobileRouter";
import {MobileSkeletonPage} from "./pages/mobile/skeletonPage";
import {DesktopSkeletonPage} from "./pages/desktop/skeletonPage";
import { YMInitializer } from 'react-yandex-metrika';
import arrowUp from './arrow_up.png'
import { isMobile } from 'react-device-detect';

function App() {
  const windowSize = window.screen.width

  useEffect(() =>{
    window.addEventListener("scroll", (e) => onEventScroll(e));

    return () => {
      window.removeEventListener("scroll", (e) => onEventScroll(e));
    }
  })

  function onEventScroll(event: any) {
    var screenHeight = window.screen.height;
    if (window.scrollY > screenHeight * 1.5) {
      updateFloationButtonState('1');
    } else {
      updateFloationButtonState('0');
    }
  }

  function onClickToFloatinButton() {
      window.scrollTo(0, 0);
  }

  function updateFloationButtonState(opacity: string) {
      var button = document.getElementById("floating-button");
      if (button == null) {
        return;
      }

      button.style.opacity = opacity;
  }

  return (
    <Suspense fallback={isMobile ? <MobileSkeletonPage/> : <DesktopSkeletonPage/> }>
      <RouterProvider router={isMobile ? mobileRouter : desktopRouter }/>
      <button className='core-floating-button' id="floating-button" onClick={onClickToFloatinButton}>
        Наверх
        <img className={'arrowUp'} src={arrowUp} alt={'ginfotech'}/>
      </button>
      <YMInitializer accounts={[95021386]} options={{webvisor: true}}/>
    </Suspense>
  );
}

export default App;
