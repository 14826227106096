import './style.css'

export const MobileSkeletonPage = () => {
  return (
    <div className={'mobile-skeleton-page'}>
      <div className={'skeleton-header skeleton-item'}/>
      <div className={'skeleton-content-header-subtitle skeleton-item'}/>
      <div className={'skeleton-content-header skeleton-item'}/>
      <div className={'skeleton-btn skeleton-item'}/>
      <div className={'skeleton-play skeleton-item'}/>
      <div className={'skeleton-row'}>
        <div className={'skeleton-column'}>
          <div className={'skeleton-about-author-title skeleton-item'}/>
          <div className={'skeleton-about-author-content skeleton-item'}/>
        </div>

        <div className={'skeleton-hero-img skeleton-item'}/>
      </div>
    </div>
  )
}