import {createBrowserRouter} from "react-router-dom";
import {lazy} from "react";
import {lazyRetry} from "./utils/lazyRetry";

const MainPage = lazy(() => lazyRetry(() => import('./pages/mobile/mainPage')))
const CoursePage = lazy(() => lazyRetry(() => import('./pages/mobile/coursePage')))
const ConsultationPage = lazy(() => lazyRetry(() => import('./pages/mobile/consultationPage')))
const MentoringPage = lazy(() => lazyRetry(() => import('./pages/mobile/mentoringPage')))
const InterviewPage = lazy(() => lazyRetry(() => import('./pages/mobile/interviewPage')))
const PrivacyPage = lazy(() => lazyRetry(() => import('./pages/mobile/privacy')))
const OfferPage = lazy(() => lazyRetry(() => import('./pages/mobile/offerPage')))
const OrganizationInfoPage = lazy( () => lazyRetry(() => import('./pages/mobile/organizationInfoPage')))


export const mobileRouter = createBrowserRouter([
  {
    path: '/',
    element: <MainPage/>
  },
  {
    path: '/course',
    element: <CoursePage/>
  },
  {
    path: '/consultation',
    element: <ConsultationPage/>
  },
  {
    path: '/interview',
    element: <InterviewPage/>
  },
  {
    path: '/mentoring',
    element: <MentoringPage/>
  },
  {
    path: '/privacy',
    element: <PrivacyPage/>
  },
  {
    path: '/offer',
    element: <OfferPage/>
  },
  {
    path: '/organization_info',
    element: <OrganizationInfoPage/>
  }
])